import React from 'react';
import styled from 'styled-components';
import { NavigationGroupFragment } from '../../../gatsby-graphql';

const FooterNavigationGroup: React.FC<Omit<NavigationGroupFragment, 'id'>> = ({
  mainLabel,
  links,
}) => (
  <div>
    <StyledMainAnchor>{mainLabel}</StyledMainAnchor>
    <div>
      {links?.map((link) => (
        <StyledLink
          key={link?.id as string}
          target="_blank"
          href={link?.anchor?.url as string}
          rel="noopener"
        >
          {link?.label}
        </StyledLink>
      ))}
    </div>
  </div>
);

const StyledMainAnchor = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  margin: 0 0 25px;
  color: ${(props) => props.theme.colors.dark.primary};
  &,
  a {
    color: ${(props) => props.theme.colors.dark.primary};
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledLink = styled.a`
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.colors.dark.tertiary};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:not(:last-of-type) {
    margin-bottom: 9px;
  }
`;

export default React.memo(FooterNavigationGroup);
