import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { MenuIcon, CloseIcon } from '../../icons';
import useHandleScroll from '../../hooks/useHandleScroll';
import { SiteNavigationFragment } from '../../../gatsby-graphql';
import { BaseContainer } from '../BaseComponents';
import Button from '../Button/Button';

interface IMobileMenuProps {
  navigation: SiteNavigationFragment['navigation'];
  onContactTrigger():void;
}

const MobileMenu: React.FC<IMobileMenuProps> = ({ navigation, onContactTrigger }) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { disableWindowScroll, enableWindowScroll } = useHandleScroll();
  const collapseProps = useSpring({
    transform: isMenuOpen ? 'translateY(0)' : 'translateY(-100%)',
  });
  const menuIconProps = useSpring({ opacity: isMenuOpen ? 0 : 1 });
  const closeIconProps = useSpring({ opacity: isMenuOpen ? 1 : 0 });

  useEffect(() => {
    if (isMenuOpen) {
      disableWindowScroll();
    } else {
      enableWindowScroll();
    }
  }, [isMenuOpen]);

  return (
    <StyledMobileMenu>
      <MobileMenuButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <animated.div style={menuIconProps}>
          <MenuIcon />
        </animated.div>
        <animated.div style={closeIconProps}>
          <CloseIcon />
        </animated.div>
      </MobileMenuButton>
      <MobileMenuCollapsibleContainer className={isMenuOpen ? 'menu-open' : ''}>
        <MobileMenuCollapsible style={collapseProps}>
          <MobileMenuCollapsibleContent>
            {navigation?.operationTypes?.map((operationType) => (
              <StyledLink
                to={`/${operationType?.slug}`}
                key={operationType?.id}
              >
                {operationType?.name}
              </StyledLink>
            ))}
            {/*navigation?.navigationItems?.map((navItem) => (
              <StyledLink to={`/${navItem?.slug}`} key={navItem?.id}>
                {navItem?.title}
              </StyledLink>
            ))*/}
            <Button
              onClick={onContactTrigger}
              label={navigation?.contactFormTriggerLabel as string}
              color="primary"
              buttonStyle="outline"
            />
          </MobileMenuCollapsibleContent>
        </MobileMenuCollapsible>
      </MobileMenuCollapsibleContainer>
    </StyledMobileMenu>
  );
};

const StyledMobileMenu = styled.div`
  ${(props) => props.theme.mq.desktop} {
    display: none;
  }
`;

const MobileMenuButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  div {
    position: absolute;
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

const MobileMenuCollapsibleContainer = styled.div`
  position: absolute;
  top: ${(props) => props.theme.sizes.headerHeight};
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 2;
  pointer-events: none;
  &.menu-open {
    pointer-events: all;
  }
`;

const MobileMenuCollapsible = styled(animated.div)`
  height: ${(props) => `calc(100vh - ${props.theme.sizes.headerHeight})`};
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: ${(props) => props.theme.colors.lightSurface};
`;

const MobileMenuCollapsibleContent = styled(BaseContainer)`
  display: grid;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.dark.primary};
  text-decoration: none;
  padding: 16px 0;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export default React.memo(MobileMenu);
