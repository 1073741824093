import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import RequestInfoForm from '../Forms/RequestInfoForm';
import {
  ContactFormFragment,
  PropertyAgentFragment,
} from '../../../gatsby-graphql';
import { CloseIcon } from '../../icons';

Modal.setAppElement(`#___gatsby`);

interface IContactFormModalProps extends ContactFormFragment {
  isOpen: boolean;
  closeModal(): void;
}

const ContactFormModal: React.FunctionComponent<IContactFormModalProps> = ({
  isOpen,
  closeModal,
  contactAgent,
  mailInputLabel,
  phoneInputLabel,
  nameInputLabel,
  submitLabel,
  title,
  copy,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Example Modal"
      onRequestClose={closeModal}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 2,
        },
        content: {
          top: '150px',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, 0)',
          padding: '30px 24px 40px',
        },
      }}
    >
      <>
        <Content>
          <button onClick={closeModal}>
            <CloseIcon />
          </button>
          <h2>{title}</h2>
          <p>{copy}</p>
        </Content>
        <RequestInfoForm
          {...{ mailInputLabel, phoneInputLabel, nameInputLabel, submitLabel }}
          toastPosition="top-center"
          agent={contactAgent as PropertyAgentFragment}
          formId="in-modal-contact"
        />
      </>
    </Modal>
  );
};

const Content = styled.div`
  h2 {
    margin-bottom: 12px;
  }
  p {
    margin-top: 0;
    margin-bottom: 2rem;
  }
  button {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 20px;
    background: transparent;
    border: none;
  }
`;

export default ContactFormModal;
