import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Logo } from '../../icons';
import { BaseContainer } from '../BaseComponents';
import {
  ContactFormFragment,
  SiteNavigationFragment,
} from '../../../gatsby-graphql';
import Button from '../Button/Button';
import ContactFormModal from '../ContactFormModal/ContactFormModal';
import useHandleScroll from '../../hooks/useHandleScroll';
import MobileMenu from './MobileMenu';

interface IHeaderProps {
  navigation: SiteNavigationFragment['navigation'];
}

const Header: React.FC<IHeaderProps> = ({ navigation }) => {
  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false);
  const { disableWindowScroll, enableWindowScroll } = useHandleScroll();

  useEffect(() => {
    if (isContactModalOpen) {
      disableWindowScroll();
    } else {
      enableWindowScroll();
    }
  }, [isContactModalOpen]);

  return (
    <StyledHeader>
      <BaseContainer>
        <InnerContainer>
          <div>
            <Link to="/" title={navigation?.title as string}>
              <StyledLogo />
            </Link>
            <DesktopNav>
              {navigation?.operationTypes?.map((operationType) => (
                <StyledMainLink
                  to={`/${operationType?.slug}`}
                  key={operationType?.id}
                >
                  {operationType?.name}
                </StyledMainLink>
              ))}
              {/*navigation?.navigationItems?.map((navItem) => (
              <StyledMainLink
                to={`/${navItem?.slug as string}`}
                key={navItem?.id}
              >
                {navItem?.title}
              </StyledMainLink>
            ))*/}
            </DesktopNav>
          </div>
          <DesktopNav>
            <Button
              label={navigation?.contactFormTriggerLabel as string}
              color="primary"
              buttonStyle="outline"
              onClick={() => setIsContactModalOpen(true)}
            />
          </DesktopNav>
          <MobileMenu
            navigation={navigation}
            onContactTrigger={() => setIsContactModalOpen(true)}
          />
          <ContactFormModal
            {...(navigation?.modalContactForm as ContactFormFragment)}
            isOpen={isContactModalOpen}
            closeModal={() => setIsContactModalOpen(false)}
          />
        </InnerContainer>
      </BaseContainer>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const StyledLogo = styled(Logo)`
  height: 40px;
  ${(props) => props.theme.mq.tablet} {
    height: 44px;
  }
  ${(props) => props.theme.mq.desktop} {
    height: 48px;  
  }
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${(props) => props.theme.sizes.headerHeight};
  & > div:first-of-type {
    display: flex;
    align-items: center;
    nav {
      margin-left: 60px;
    }
  }
`;

const DesktopNav = styled.nav`
  display: none;
  ${(props) => props.theme.mq.desktop} {
    display: block;
  }
`;

const StyledMainLink = styled(Link)`
  color: ${(props) => props.theme.colors.secondary};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-decoration: none;
  transition: ${(props) => props.theme.generics.transitionTime};

  &:not(:last-of-type) {
    margin-right: 26px;
  }

  &:hover {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: underline;
  }
`;

export default React.memo(Header);
