import { DefaultTheme } from 'styled-components';

const breakpoints = {
  bigPhone: 400,
  tablet: 768,
  desktop: 1024,
};
//6B0504
//A8201A
//B80C09
//890620
const theme: DefaultTheme = {
  colors: {
    primary: '#FF8300',
    secondary: '#6d7074',
    lightSurface: '#FFFFFF',
    grayBackground: '#F8F8F8',
    white: '#FFFFFF',
    inactiveBackground: '#EBEFEE',
    error: '#F43E15',
    dark: {
      primary: '#4B4B4B',
      secondary: '#4B4B4B',
      tertiary: '#5F5F5F',
    },
  },
  sizes: {
    buttonBorderRadius: '4px',
    containerMaxWidth: 1240,
    headerHeight: '80px',
  },
  fonts: {
    helvetica: `"Helvetica Neue", sans-serif`,
  },
  generics: {
    transitionTime: 'all 0.3s ease-in-out',
  },
  breakpoints,
  mq: {
    bigPhone: `@media (min-width: ${breakpoints.bigPhone}px)`,
    tablet: `@media (min-width: ${breakpoints.tablet}px)`,
    untilDesktop: `@media (max-width: ${breakpoints.desktop - 1}px)`,
    desktop: `@media (min-width: ${breakpoints.desktop}px)`,
  },
};

export default theme;
