import React from 'react';
import Carousel from 'nuka-carousel';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { PropertyCardFragment } from '../../../gatsby-graphql';
import NavButton from '../NavButton/NavButton';

export type ImageResolver = 'featured' | 'desktopGrid' | 'listingGrid';

interface IPropertyCardMediaSliderProps {
  images: PropertyCardFragment['images'];
  badge: PropertyCardFragment['badge'];
  imageResolver: ImageResolver;
}

const PropertyCardMediaSlider: React.FC<IPropertyCardMediaSliderProps> = ({
  images,
  badge,
  imageResolver,
}) => (
  <Container>
    <Carousel
      wrapAround
      renderBottomCenterControls={() => null}
      renderCenterLeftControls={({ previousSlide }) => (
        <NavButton
          onClick={previousSlide}
          ariaLabel="anterior"
          className="prev"
        />
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <NavButton onClick={nextSlide} ariaLabel="siguiente" className="next" />
      )}
    >
      {images?.map((image) => (
        <GatsbyImage
          key={image?.id}
          image={image?.[imageResolver]}
          loading="lazy"
          alt={image?.description as string}
        />
      ))}
    </Carousel>
    {badge && <Badge>{badge}</Badge>}
  </Container>
);

const Container = styled.div`
  border-radius: 8px;
  overflow: hidden;
  position: relative;
`;

const Badge = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  font-size: 14px;
  line-height: 14px;
  font-weight: normal;
  letter-spacing: 0.25px;
  padding: 6px 21px;
  border-top-left-radius: 4px;
`;

PropertyCardMediaSlider.defaultProps = {
  imageResolver: 'desktopGrid',
};

export default React.memo(PropertyCardMediaSlider);
