import { useState } from 'react';
import { useLocation } from '@reach/router';
import Cookies from 'js-cookie';
import axios from 'axios';
import { toast, ToastPosition } from 'react-toastify';
import { removeHtmlTags } from '../utils';

const HUBSPOT_SUBMIT_ENDPOINT =
  'https://api.hsforms.com/submissions/v3/integration/submit';

interface IUseHubSpotFormParams {
  formId: string;
  toastPosition?: ToastPosition;
}

interface IUseHubSpotForm {
  loading: boolean;
  error: string;
  data: Record<string, unknown>;
  submitForm(values: any, showToast: boolean): void;
}

const useHubspotForm = ({
  formId,
  toastPosition,
}: IUseHubSpotFormParams): IUseHubSpotForm => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [data, setData] = useState({});
  const location = useLocation();

  const submitForm = async (values, showToast) => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${HUBSPOT_SUBMIT_ENDPOINT}/${process.env.GATSBY_HUBSPOT_PORTAL_ID}/${formId}`,
        {
          submittedAt: `${Date.now()}`,
          fields: Object.keys(values).map((key) => ({
            name: key,
            value: values[key],
          })),
          context: {
            hutk: Cookies.get('hubspotutk'),
            pageUri: location.href,
            pageName: document.title,
          },
        },
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );
      setLoading(false);
      setData(data);
      if (showToast) {
        toast.success(removeHtmlTags(data.inlineMessage), {
          position: toastPosition,
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
      }
    } catch (error) {
      setError(error);
      setLoading(loading);
    }
  };

  return {
    loading,
    error,
    data,
    submitForm,
  };
};

export default useHubspotForm;
