import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import {
  OperationTypeFragment,
  PropertyTypeFragment,
} from '../../../gatsby-graphql';

interface ISimpleFooterNavigationGroup {
  operationType: OperationTypeFragment;
  propertyTypes: PropertyTypeFragment[];
}

const SimpleFooterNavigationGroup: React.FC<ISimpleFooterNavigationGroup> = ({
  operationType,
  propertyTypes,
}) => (
  <div>
    <StyledMainAnchor>
      <Link to={`/${operationType.slug}`}>{operationType.name}</Link>
    </StyledMainAnchor>
    <div>
      {propertyTypes?.map((propertyType) => (
        <StyledLink
          key={propertyType?.id}
          to={`/${operationType.slug}/${propertyType.slug}`}
        >
          {propertyType.name}
        </StyledLink>
      ))}
    </div>
  </div>
);

const StyledMainAnchor = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  margin: 0 0 25px;
  color: ${(props) => props.theme.colors.dark.primary};
  &,
  a {
    color: ${(props) => props.theme.colors.dark.primary};
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledLink = styled(Link)`
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.colors.dark.tertiary};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:not(:last-of-type) {
    margin-bottom: 9px;
  }
`;

export default React.memo(SimpleFooterNavigationGroup);
