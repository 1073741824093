import React, { useEffect, useState } from 'react';
import { FieldHookConfig, useField } from 'formik';
import styled from 'styled-components';

interface IFormGroupProps {
  label: string;
  formId: string;
  error?: string | boolean;
}

const FormGroup = ({
  label,
  error,
  formId,
  ...props
}: IFormGroupProps & FieldHookConfig<string>) => {
  const [useMiniLabel, setUseMiniLabel] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [field] = useField(props);

  const handleInputFocus = () => {
    setUseMiniLabel(true);
    setIsFocus(true);
  };
  const handleInputBlur = (e: React.FocusEvent<any>) => {
    field.onBlur(e);
    if (field.value.length === 0) setUseMiniLabel(false);
    setIsFocus(false);
  };

  useEffect(() => {
    if (!isFocus) setUseMiniLabel(field.value.length > 0);
  }, [field.value]);

  return (
    <FormItem {...{ error, useMiniLabel, isFocus }}>
      <label htmlFor={`${formId}-${props.name}`}>{label}</label>
      <input
        {...field}
        id={`${formId}-${props.name}`}
        placeholder={props.placeholder}
        type={props.type}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
      />
    </FormItem>
  );
};

const FormItem = styled.div<{
  isFocus: boolean;
  error?: string | boolean;
  useMiniLabel: boolean;
}>`
  position: relative;
  margin-bottom: 16px;
  input {
    transition: all 0.2s ease-in-out;
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.dark.secondary};
    border: 1px solid
      ${(props) =>
        props.error
          ? props.theme.colors.error
          : props.isFocus
          ? props.theme.colors.dark.secondary
          : props.theme.colors.inactiveBackground};
    border-radius: 8px;
    width: 100%;
    padding: 22px 12px 10px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 1;
    &:focus {
      outline: none;
    }
  }
  label {
    display: flex;
    align-items: center;
    position: absolute;
    left: 12px;
    color: ${(props) =>
      props.error
        ? props.theme.colors.error
        : props.theme.colors.dark.secondary};
    top: ${(props) => (props.useMiniLabel ? '6px' : 0)};
    bottom: ${(props) => (props.useMiniLabel ? 'auto' : 0)};
    font-size: ${(props) => (props.useMiniLabel ? '12px' : '16px')};
    font-weight: ${(props) => (props.useMiniLabel ? 'bold' : 'normal')};
    margin: auto 0;
  }
`;

export default FormGroup;
