import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import styled from 'styled-components';
import { SiteNavigationFragment } from '../../../gatsby-graphql';
import theme from '../../styles/theme';
import Header from '../Header/Header';
import GlobalStyles from '../../styles/GlobalStyles';
import Footer from '../Footer/Footer';
import Seo, { ISeoProps } from '../Seo/Seo';

interface ILayoutProps extends ISeoProps {
  navigation: SiteNavigationFragment['navigation'];
  footer: SiteNavigationFragment['footer'];
}

const Layout: React.FC<ILayoutProps> = ({
  navigation,
  children,
  footer,
  ...seo
}) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <Seo {...seo} />
    <Header {...{ navigation }} />
    <StyledMain>{children}</StyledMain>
    <ToastContainer />
    <Footer {...{ footer }} />
  </ThemeProvider>
);

const StyledMain = styled.main`
  min-height: 100vh;
`;

export default Layout;
