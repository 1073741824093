import * as React from 'react';
import Helmet from 'react-helmet';
import useSiteMetadata from '../../graphql/hooks/useSiteMetadata';

export interface ISeoProps {
  title?: string;
  description?: string;
  image?: string;
}

const Seo: React.FC<ISeoProps> = ({ description, title, image }) => {
  const siteMetadata = useSiteMetadata();
  return (
    <Helmet
      htmlAttributes={{ lang: 'es' }}
      title={
        title
          ? `${title} | ${String(siteMetadata?.title)}`
          : String(siteMetadata?.title)
      }
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:title`,
          content: String(siteMetadata?.title),
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:image`,
          content: image,
        },
      ]}
    />
  );
};

Seo.defaultProps = {
  description: '',
};

export default Seo;
