export const createPropertyLink = (
  slug: string,
  operationType: string,
  propertyType: string
): string => `/${operationType}/${propertyType}/${slug}`;

export const formatPrice = (price: number): string =>
  new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(price);

export const _window: Window & typeof globalThis =
  typeof window !== `undefined` ? window : ({} as Window & typeof globalThis);

export const isBuildTime = typeof window === 'undefined';

export const isOdd = (value: number): boolean => value % 2 === 0;

export const getSelectOptions = (
  arr: { slug: string; name: string }[]
): { value: string; label: string }[] =>
  arr?.map((option) => ({
    value: option?.slug,
    label: option?.name,
  }));

export const getPropertyTypeFromPath = (path: string): string => {
  let splitPath = path.trim().split('/');
  splitPath = splitPath.filter((str) => str);
  return splitPath.length > 1 ? splitPath[1] : '';
};

export const updateOperationTypeInPath = (
  path: string,
  newOperationType?: string | null
): string => {
  const pathTail = path.trim().split('/').splice(2);
  return `${removeForwardSlash(`/${newOperationType}/${pathTail.join('/')}`)}`;
};

export const removeForwardSlash = (str: string): string => {
  if (str.charAt(str.length - 1) === '/') return str.slice(0, -1);
  return str;
};

export const updatePropertyTypeInPath = (
  path: string,
  newPropertyType?: string | null,
  localized = false
): string => {
  const isReset = newPropertyType === 'reset';
  let splitPath = path.trim().split('/');
  splitPath = splitPath.filter((str) => str);
  if (!localized) {
    if (splitPath.length > 1) {
      if (isReset) {
        splitPath.splice(1, 1);
      } else {
        splitPath[1] = `${newPropertyType}`;
      }
      return `/${splitPath.join('/')}`;
    }
    return isReset ? path : `${removeForwardSlash(path)}/${newPropertyType}`;
  } else {
    return isReset
      ? path
      : `/${splitPath[0]}/${newPropertyType}/${splitPath[1]}`;
  }
};

export const updatePropertyLocationInPath = (
  path: string,
  newPropertyLocation?: string | null,
  localized = false
): string => {
  const isReset = newPropertyLocation === 'reset';
  let splitPath = path.trim().split('/');
  splitPath = splitPath.filter((str) => str);
  if (!localized) {
    if (splitPath.length > 2) {
      if (isReset) {
        splitPath.splice(2, 1);
      } else {
        splitPath[2] = `${newPropertyLocation}`;
      }
      return `/${splitPath.join('/')}`;
    }
    return isReset
      ? path
      : `${removeForwardSlash(path)}/${newPropertyLocation}`;
  } else {
    if (isReset) {
      splitPath.splice(1, 1);
    } else {
      splitPath[1] = `${newPropertyLocation}`;
    }
    return `/${splitPath.join('/')}`;
  }
};

export const removeHtmlTags = (str: string): string =>
  str.replace(/(<([^>]+)>)/gi, '');

export const createWhatsAppLink = (phone: string, text: string): string =>
  `https://wa.me/${phone}${text ? `?text=${encodeURI(text)}` : ''}`;
