import { css } from 'styled-components';

export interface IStyledButtonProps {
  readonly color: 'primary' | 'secondary';
}

const solidStyle = css<IStyledButtonProps>`
  background-color: ${(props) => props.theme.colors[props.color]};
  border-color: ${(props) => props.theme.colors[props.color]};
  color: ${(props) => props.theme.colors.white};
  svg {
    fill: ${(props) => props.theme.colors.white};
  }
`;

const outlineStyle = css<IStyledButtonProps>`
  background-color: transparent;
  border-color: ${(props) => props.theme.colors[props.color]};
  color: ${(props) => props.theme.colors[props.color]};
  svg {
    fill: ${(props) => props.theme.colors[props.color]};
  }
`;

const textStyle = css<IStyledButtonProps>`
  border-color: transparent;
  color: ${(props) => props.theme.colors[props.color]};
  svg {
    fill: ${(props) => props.theme.colors[props.color]};
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const buttonBasicStyle = css`
  display: inline-block;
  color: white;
  padding: 10px 30px;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  background: none;
  border-radius: ${(props) => props.theme.sizes.buttonBorderRadius};
  border: 2px solid;
  transition: ${(props) => props.theme.generics.transitionTime};
  text-decoration: none;
  font-weight: 600;
`;

export const buttonStyle = css<IStyledButtonProps>`
  ${buttonBasicStyle}
  &:focus {
    outline: none;
    box-shadow: ${(props) => `0 0 10px ${props.theme.colors[props.color]}`};
  }

  &.solid {
    ${solidStyle}
    &:hover {
      ${outlineStyle}
    }
  }

  &.outline {
    ${outlineStyle}
    &:hover {
      ${solidStyle}
    }
  }

  &.text {
    ${textStyle}
  }
`;
