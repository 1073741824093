import React from 'react';
import styled from 'styled-components';
import { BaseContainer } from '../BaseComponents';
import {
  OperationTypeFragment,
  PropertyTypeFragment,
  SiteNavigationFragment,
} from '../../../gatsby-graphql';
import { Logo } from '../../icons';
import SimpleFooterNavigationGroup from './SimpleFooterNavigationGroup';
import FooterNavigationGroup from './FooterNavigationGroup';

interface IFooterProps {
  footer: SiteNavigationFragment['footer'];
}

const Footer: React.FC<IFooterProps> = ({ footer }) => (
  <FooterContainer>
    <BaseContainer>
      <StyledFooter>
        <LogoContainer>
          <Logo />
          <p>{footer?.copy}</p>
          <span>
            Plusvalia Multinmobiliaria<sup>®</sup> {new Date().getFullYear()}
          </span>
        </LogoContainer>
        <FooterLinksContainer>
          {footer?.operationTypes?.map((operationType) => (
            <SimpleFooterNavigationGroup
              key={operationType?.id}
              operationType={operationType as OperationTypeFragment}
              propertyTypes={footer?.propertyTypes as PropertyTypeFragment[]}
            />
          ))}
          {footer?.navigationGroups?.map((navigationGroup) => (
            <FooterNavigationGroup
              key={navigationGroup?.id}
              mainLabel={navigationGroup?.mainLabel}
              links={navigationGroup?.links}
            />
          ))}
        </FooterLinksContainer>
      </StyledFooter>
    </BaseContainer>
  </FooterContainer>
);

const FooterContainer = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 100px;
`;

const StyledFooter = styled.footer`
  padding: 60px 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 48px;
  ${(props) => props.theme.mq.desktop} {
    gap: 68px;
    grid-template-columns: 3fr 9fr;
  }
`;

const LogoContainer = styled.div`
  p {
    color: ${(props) => props.theme.colors.dark.tertiary};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin: 20px 0 0;
  }
  span {
    color: ${(props) => props.theme.colors.dark.tertiary};
    display: block;
    font-size: 12px;
    letter-spacing: 0;
    margin-top: 20px;
  }
`;

const FooterLinksContainer = styled.div`
  display: grid;
  gap: 34px;
  grid-template-columns: repeat(2, 1fr);
  ${(props) => props.theme.mq.tablet} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export default React.memo(Footer);
