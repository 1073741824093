import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { ToastPosition } from 'react-toastify';
import Button from '../Button/Button';
import useHubspotForm from '../../hooks/useHubspotForm';
import { PropertyAgentFragment } from '../../../gatsby-graphql';
import { createWhatsAppLink } from '../../utils';
import FormGroup from './FormGroup';

interface IRequestInfoFormProps {
  nameInputLabel?: string | null;
  mailInputLabel?: string | null;
  phoneInputLabel?: string | null;
  submitLabel?: string | null;
  agent: PropertyAgentFragment;
  formId: string;
  toastPosition?: ToastPosition;
}

interface IRequestInfoFormValues {
  firstname: string;
  email: string;
  phone: string;
  message?: string;
}

const ValidationSchema: Yup.SchemaOf<IRequestInfoFormValues> = Yup.object().shape(
  {
    firstname: Yup.string().required(),
    email: Yup.string().email().required(),
    phone: Yup.string()
      .required()
      .matches(/^[0-9]+$/)
      .min(10)
      .max(10),
    message: Yup.string(),
  }
);

const RequestInfoForm: React.FunctionComponent<IRequestInfoFormProps> = ({
  submitLabel,
  phoneInputLabel,
  mailInputLabel,
  nameInputLabel,
  formId,
  agent,
  toastPosition,
}) => {
  const { submitForm, loading } = useHubspotForm({
    formId: 'b55971dd-0f1f-4a58-bc88-bad7773d5284',
    toastPosition,
  });
  const initialValues: IRequestInfoFormValues = {
    firstname: '',
    email: '',
    phone: '',
    message: '',
  };

  const handleFormSubmit = async (
    values: IRequestInfoFormValues,
    formikHelpers: FormikHelpers<IRequestInfoFormValues>
  ) => {
    await submitForm(values, true);
    formikHelpers.setSubmitting(false);
    formikHelpers.resetForm();
    const whatsAppText = `Hola ${agent?.name}, estoy interesado en vender mi propiedad.`;
    window.open(createWhatsAppLink(agent?.phone as string, whatsAppText));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <StyledForm id={formId}>
          <FormGroup
            formId={formId}
            name="firstname"
            label={nameInputLabel as string}
            error={errors.firstname && touched.firstname}
          />
          <FormGroup
            formId={formId}
            name="phone"
            type="tel"
            label={phoneInputLabel as string}
            error={errors.phone && touched.phone}
          />
          <FormGroup
            formId={formId}
            name="email"
            type="email"
            label={mailInputLabel as string}
            error={errors.email && touched.email}
          />
          <Button
            loading={loading}
            type="submit"
            label={submitLabel as string}
            buttonStyle="solid"
            color="primary"
            disabled={isSubmitting}
          />
        </StyledForm>
      )}
    </Formik>
  );
};

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  button {
    margin-top: 1rem;
  }
`;

RequestInfoForm.defaultProps = {
  toastPosition: 'bottom-right',
};

export default RequestInfoForm;
