import React from 'react';
import styled from 'styled-components';
import { ArrowIcon } from '../../icons';

interface INavButtonProps {
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  ariaLabel: string;
  className: string;
}

const NavButton: React.FC<INavButtonProps> = ({
  onClick,
  ariaLabel,
  className,
}) => (
  <StyledNavButton
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      if (onClick) onClick(e);
    }}
    aria-label={ariaLabel}
    className={className}
  >
    <ArrowIcon />
  </StyledNavButton>
);

const StyledNavButton = styled.button`
  padding: 0;
  height: 24px;
  width: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.lightSurface};
  border: 0;
  cursor: pointer;
  svg {
    g {
      fill: ${(props) => props.theme.colors.secondary};
    }
  }
  &.prev {
    margin-left: 16px;
  }
  &.next {
    margin-right: 16px;
    svg {
      transform: rotate(180deg);
    }
  }
`;

export default React.memo(NavButton);
