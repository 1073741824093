import styled from 'styled-components';

export const BaseContainer = styled.div`
  padding-right: 16px;
  padding-left: 16px;
  max-width: ${(props) => props.theme.sizes.containerMaxWidth}px;
  margin: auto;

  @media (min-width: ${(props) => props.theme.sizes.containerMaxWidth + 32}px) {
    padding-right: 0;
    padding-left: 0;
  }
`;
