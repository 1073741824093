import React from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { buttonStyle, IStyledButtonProps } from '../../styles/globals/Buttons';

export type ButtonColor = 'primary' | 'secondary';

export type ButtonStyle = 'solid' | 'outline' | 'text';

export interface IButtonProps {
  label: string;
  color: ButtonColor;
  buttonStyle: ButtonStyle;
  loading?: boolean;
}

const Button: React.FC<
  IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ label, buttonStyle, loading, ...props }) => (
  <StyledButton className={`${buttonStyle} ${props.className}`} {...props}>
    {!loading ? label : <Loader type="ThreeDots" height={14} />}
  </StyledButton>
);

const StyledButton = styled.button<IStyledButtonProps>`
  ${buttonStyle}
`;

export default React.memo(Button);
