import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyles = createGlobalStyle`
  ${normalize}
  * {
    box-sizing: border-box;
    font-feature-settings: "lnum";
    &:focus {
      outline: 1px solid ${(props) => props.theme.colors.primary};
    }
  }
  body {
    font-family: 'Raleway', sans-serif;
  }
  html,
  body {
    overflow-x: hidden;
    position: relative;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  input {
    -webkit-appearance: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }
`;

export default GlobalStyles;
